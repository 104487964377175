import { getPersonDetailLink } from '@/contact/person/utils';
import { AddressView } from '@/contact/person/view/address-view.component';
import { PersonView } from '@/contact/person/view/person-view.component';
import { useOrderActions } from '@/order/shared/actions/actions.hooks';
import { OrderItemsOverview } from '@/order/shared/order-items-overview/order-items-overview.component';
import { OrderStatusChip } from '@/order/shared/status/order-status-chip.component';
import { Box } from '@/ui/box/box.component';
import { CopyId } from '@/ui/copy-id/copy-id.component';
import { Formatted } from '@/ui/formatted/formatted.component';
import { Grid } from '@/ui/grid/grid.component';
import { HorizontalTable } from '@/ui/horizontal-table/horizontal-table.component';
import { LabeledItem } from '@/ui/labeled-item/labeled-item.component';
import { Loader } from '@/ui/loader/loader.component';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../../../frame';
import { Button } from '../../../ui/button/button.component';
import { useOrderQuery } from './view.generated';

type OrderViewParams = {
  id?: string;
};

export const View: ComponentType<RouteComponentProps<OrderViewParams>> = (
  props,
) => {
  const { id } = props;
  const orderQuery = useOrderQuery({ variables: { id } });

  const order = orderQuery.data?.adminOrder;

  const { modalDelete, askForDelete } = useOrderActions(() => {
    navigate('/orders');
  });

  if (!order) {
    return <Loader />;
  }

  return (
    <>
      <Frame.SubTitle>
        {/* {bookingQuery.data
          ? bookingQuery.data?.adminBooking.id
          : 'Unbekannte Buchung'} */}
      </Frame.SubTitle>
      <Frame.ActionBar
        left={
          <>
            <CopyId id={order.id} />
          </>
        }
      >
        <Button label="Löschen" error onClick={() => askForDelete(order)} />
        <Button label="Zurück" linkTo="/orders" />
      </Frame.ActionBar>
      <Frame.Content>
        {modalDelete}
        <Grid.Row>
          <Grid.Column>
            <Box
              title={
                <>
                  Bestellung <OrderStatusChip order={order} />
                </>
              }
              withMargin
            >
              <LabeledItem
                label="Eingegangen am"
                value={<Formatted.Date value={order.createdAt} />}
              />
              <LabeledItem multiline label="Kommentar" value={order.comment} />
              {order.options && (
                <LabeledItem
                  label="Weitere Angaben zur Bestellung"
                  value={<HorizontalTable data={order.options} />}
                />
              )}
            </Box>
            <Box
              title={
                <>
                  Kunde{' '}
                  <Button
                    linkTo={getPersonDetailLink(
                      order.customer.person,
                      'customers',
                    )}
                    label="Details"
                    small
                    primary
                  />
                </>
              }
            >
              <LabeledItem
                value={order.customer.username}
                label="Benutzername"
              />
              <PersonView person={order.customer.person} hideAddress />
            </Box>
          </Grid.Column>
          <Grid.Column>
            <Box title="Positionen" withMargin>
              <OrderItemsOverview order={order} />
            </Box>
            <Box title="Rechnungsadresse">
              <AddressView
                address={
                  order.useInvoiceAddress
                    ? order.customer.person.invoiceAddress
                    : order.customer.person.address
                }
              />
            </Box>
          </Grid.Column>
        </Grid.Row>
      </Frame.Content>
    </>
  );
};

/* import { InfoHeader } from '@/ui/info-header/info-header.component';
import { RouteComponentProps } from '@reach/router';
import React, { ComponentType } from 'react';
import { Frame } from '../../../frame';
import { Button } from '../../../ui/button/button.component';
import { useBookingQuery } from './view.generated';

type BookingViewParams = {
  id?: string;
};

export const View: ComponentType<RouteComponentProps<BookingViewParams>> = (
  props,
) => {
  const bookingQuery = useBookingQuery({
    variables: { id: props.id },
    skip: props.id === undefined,
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <Frame.SubTitle>
        {bookingQuery.data
          ? bookingQuery.data?.adminBooking.id
          : 'Unbekannte Buchung'}
        {}
      </Frame.SubTitle>
      <Frame.ActionBar>
        <Button label="Zurück" linkTo="/course-handling" />
      </Frame.ActionBar>
      <Frame.Content>
      <Grid.Row>
      <Grid.Column>
      <Box title="Bestellung">
      Hallo</Box></Grid.Column></Grid.Row>
      </Frame.Content>
    </>
  );
};
 */
